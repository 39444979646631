@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: 'Poppins', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

nav {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  z-index: 1;
}

nav .header1 {
  width: 35%;
  background-color: #08003a;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 2% 0 2% 15%;
  line-height: 0%;
  -webkit-clip-path: polygon(0 0, 100% 0%, 84.5% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 84.5% 100%, 0% 100%);
  position: relative;
  z-index: 1;
  height: 115px;
}

nav .header1 p {
  color: #ff0f5f;
  font-size: 32px;
  font-weight: 900;
}

nav .header2 {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: burlywood;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 0 0 0;
  position: absolute;
  right: 0;
}

nav .header2 .info {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #e6e6e6;
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0 10% 0 0;
  font-size: 80%;
  border-bottom: 1px solid #cccccc;
}

nav .header2 .info .infocontact {
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0;
}

nav .header2 .info .infocontact span {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

nav .header2 .info .infocontact a {
  text-decoration: none;
  color: inherit;
  margin-left: 5px;
}

nav .header2 .info .infosocial {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

nav .header2 .info .infosocial p {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-left: 1px solid #cacaca;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 115%;
  color: grey;
  cursor: pointer;
}

nav .header2 .info .infosocial p a {
  text-decoration: none;
  color: grey;
}

nav .header2 .info .infosocial p a .fa-facebook-f:hover {
  color: #4267B2;
}

nav .header2 .info .infosocial p a .fa-instagram:hover {
  color: #fc31b8;
}

nav .header2 .info .infosocial p a .fa-youtube:hover {
  color: red;
}

nav .header2 .pages {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: white;
  width: 100%;
  height: 100%;
  padding-left: 15%;
  padding-right: 11%;
}

nav .header2 .pages li {
  list-style: none;
  width: 100%;
  padding: 2% 0 2% 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

nav .header2 .pages li a {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 90%;
  text-decoration: none;
  font-size: 87%;
  color: #1b1919;
  font-weight: bolder;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

nav .header2 .pages li a:hover {
  border-bottom: 2px solid #ff0f5f;
}

.banner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url("https://www.zurich.com/-/media/project/zurich/dotcom/media/magazine/2020/images/stage-construction-site.jpg?h=1250&iar=0&w=2500&rev=6eb3e8d029bd4dde9ae63a0cd29d5d89&hash=974BF71EF63F15D3828F23516782401E");
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://www.zurich.com/-/media/project/zurich/dotcom/media/magazine/2020/images/stage-construction-site.jpg?h=1250&iar=0&w=2500&rev=6eb3e8d029bd4dde9ae63a0cd29d5d89&hash=974BF71EF63F15D3828F23516782401E");
  background-size: cover;
  z-index: -2;
}

.banner div {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  line-height: 1;
}

.banner div h1 {
  font-size: 50px;
  font-weight: 30px;
}

.banner div p {
  font-size: 20px;
}

.banner div button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 4% 15%;
  color: white;
  background-color: #ff0f5f;
  text-align: center;
  font-size: 100%;
  cursor: pointer;
}

.clients {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 100%;
  padding: 2% 15%;
}

.clients .clientdescription {
  color: grey;
}

.clients .clientdescription h1 {
  font-size: 170%;
  font-weight: 900;
}

.clients .clientdescription h1 span {
  color: #ff0f5f;
}

.clients .clientimg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding: 0 10%;
}

.clients .clientimg img {
  width: 15%;
  height: auto;
}

.ourexpertise {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 110%;
  text-align: center;
  position: absolute;
  top: 160%;
  color: grey;
  background-color: #dfdfdf;
}

.ourexpertise h2 {
  font-size: 170%;
  font-weight: 900;
}

.ourexpertise h2 span {
  color: #ff0f5f;
}

.ourexpertise img {
  width: 18%;
  height: 29%;
  margin: 7% 0;
}

.ourexpertise h1 {
  font-size: 170%;
  margin: 7% 0;
  padding: 0 5%;
}

.ourexpertise h1 p {
  color: black;
  font-size: 57%;
  font-weight: lighter;
  margin: 0;
  padding: 2% 0;
}

.ourexpertise h1 button {
  border: none;
  padding: 2% 5%;
  color: white;
  background-color: #ff0f5f;
  text-align: center;
  font-size: 60%;
  cursor: pointer;
  border-radius: 7px;
}

.ourexpertise .head1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ourexpertise .head1 .expertise1 {
  padding: 0% 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ourexpertise .head1 .expertise2 {
  padding: 0% 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ourexpertise .head2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ourexpertise .head2 .expertise3 {
  padding: 0% 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ourexpertise .head2 .expertise4 {
  padding: 0% 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.about {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 84%;
  text-align: right;
  position: absolute;
  top: 282%;
  color: grey;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.about .description {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
  padding: 4% 0 0 9%;
}

.about .description h1 {
  font-size: 32px;
  font-weight: 900;
  margin: 0;
}

.about .description h1 span {
  color: #ff0f5f;
}

.about .description p span {
  margin: 0;
  font-size: 24px;
  font-weight: bolder;
  padding: 0 0 0 30%;
  color: black;
}

.about .description button {
  background-color: white;
  outline: none;
  border: 1px solid black;
  border-radius: 4px;
  padding: 2% 9%;
  font-size: 17px;
}

.about .imageshape {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
  text-align: center;
  margin: 0;
  padding: 0 2% 0 0;
}

.about .imageshape img {
  margin: 0;
  width: 72%;
  height: 80%;
}

.ourprojects {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 370%;
  color: grey;
}

.ourprojects h1 {
  font-size: 32px;
  font-weight: 900;
  margin: 0;
}

.ourprojects h1 span {
  color: #ff0f5f;
}

.ourprojects .projectbutton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 30%;
  list-style: none;
}

.ourprojects .projectbutton .title {
  font-size: 15px;
  cursor: pointer;
}

.ourprojects .projectbutton .title:hover {
  border-bottom: 2px solid #ff0f5f;
}

.ourprojects .projectbutton :active {
  color: #ff0f5f;
}

.ourprojects .projectimages {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}

.ourprojects .projectimages img {
  width: 250px;
  height: 200px;
  margin: 1.5% 1.5%;
}

.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer .foot {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 50%;
  text-align: center;
  position: absolute;
  top: 480%;
  color: white;
  background-color: #161325;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2%;
}

.footer .foot .foot1 {
  width: 30%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.footer .foot .foot1 .header1 {
  width: 100%;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 50px 0 50px 150px;
  line-height: 0ch;
  text-align: left;
  font-size: 13px;
}

.footer .foot .foot1 .header1 p {
  color: #ff0f5f;
  font-size: 25px;
  font-weight: 900;
  text-align: left;
}

.footer .foot .foot1 button {
  width: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  background-color: #29263f;
  color: white;
  font-size: 16px;
  padding: 3% 0;
  border: 1px solid #383158;
  border-radius: 5px;
  margin: -32px 30px 0 148px;
}

.footer .foot .foot2 {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 2%;
}

.footer .foot .foot2 .contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 17px;
  padding: 2% 0% 2% 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 2px solid rgba(134, 134, 134, 0.363);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 12%;
}

.footer .foot .foot2 .contact .cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 2% 0 0;
  margin-right: 9%;
}

.footer .foot .foot2 .contact .cont i {
  font-size: 30px;
  color: #ff0f5f;
  margin-right: 5px;
}

.footer .foot .foot2 .contact .cont div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  padding: 0 0 0 6%;
  font-size: 12px;
}

.footer .foot .foot2 .contact .cont div .pi {
  color: #ff0f5f;
  font-size: 14px;
  font-weight: bold;
}

.footer .foot .foot2 .contact .cont div span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 120%;
  color: #a8a8a8;
}

.footer .foot .foot2 .contact .cont div span a {
  text-decoration: none;
  color: #a8a8a8;
}

.footer .foot .foot2 .links {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.footer .foot .foot2 .links .same {
  margin-top: 15px;
  margin-right: 120px;
  text-align: left;
  line-height: 0.5ch;
}

.footer .foot .foot2 .links .same h1 {
  font-size: 17px;
  font-weight: lighter;
  margin-bottom: 30px;
}

.footer .foot .foot2 .links .same a {
  text-decoration: none;
  color: #a8a8a8;
  font-size: 13px;
}

.footer .copyright {
  width: 100%;
  background-color: black;
  color: #a8a8a8;
  font-size: 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1% 5%;
  position: absolute;
  top: 529%;
}
