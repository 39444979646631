@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


*{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// NAVBAR//
nav{
    margin: 0;
    padding: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    .header1{
        width: 35%;
        background-color: #08003a;
        color: white;
        box-sizing: border-box;
        padding: 2% 0 2% 15%;
        line-height: 0%;
        clip-path: polygon(0 0, 100% 0%, 84.5% 100%, 0% 100%);
        position: relative;
        z-index: 1;
        height: 115px;

        p{
            color: #ff0f5f;
            font-size: 32px;
            font-weight: 900;
        }
    }

    .header2{
        width: 70%;
        display: flex;
        background-color: burlywood;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 0 0 0;
        position: absolute;
        right: 0;

        .info{
            box-sizing: border-box;
            background-color: #e6e6e6;
            color: black;
            display: flex;
            justify-content: space-evenly;
            padding: 0 10% 0 0;
            font-size: 80%;
            border-bottom: 1px solid #cccccc;
        
            .infocontact{
                width: 80%;
                display: flex;  
                justify-content: space-evenly;      
                padding: 0;

                span{
                    padding: 0;
                    margin: 0;
                    font-weight: bold;
                }
                a{
                    text-decoration: none;
                    color: inherit;
                    margin-left: 5px;
                }
            }

            .infosocial{
                width: 20%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                padding: 0;

                p{  
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    border-left: 1px solid #cacaca;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    font-size: 115%;
                    color: grey;
                    cursor: pointer;

                    a{
                        text-decoration: none;
                        color: grey;

                        .fa-facebook-f:hover{
                            color: #4267B2;
                        }
                        .fa-instagram:hover{
                            color: #fc31b8;
                        }
                        .fa-youtube:hover{
                            color: red;
                        }
                    }
                }
            }
        }

        .pages{
            box-sizing: border-box;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            background-color: white;
            width: 100%;
            height: 100%;
            padding-left: 15%;
            padding-right: 11%;
            

            li{
                list-style: none;
                width: 100%;
                padding: 2% 0 2% 0;
                box-sizing: border-box;

                a{
                    box-sizing: border-box;
                    width: 90%;
                    text-decoration: none;
                    font-size: 87%;
                    color: rgb(27, 25, 25);
                    font-weight: bolder;
                    display: flex;
                    justify-content: space-evenly;
                }
                a:hover{
                    border-bottom: 2px solid #ff0f5f;
                }
            }
        }
    }
}

// NAVBAR//

//*************************************************************************************************************//

//HOME SECTION//

.banner{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://www.zurich.com/-/media/project/zurich/dotcom/media/magazine/2020/images/stage-construction-site.jpg?h=1250&iar=0&w=2500&rev=6eb3e8d029bd4dde9ae63a0cd29d5d89&hash=974BF71EF63F15D3828F23516782401E");
    background-size: cover;
    z-index: -2;

    div{
        text-align: center;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        line-height: 1;

        h1{
            font-size: 50px;
            font-weight: 30px;
        }

        p{
            font-size: 20px;
        }

        button {
            border: none;
            outline: 0;
            display: inline-block;
            padding: 4% 15%;
            color: rgb(255, 255, 255);
            background-color: #ff0f5f;
            text-align: center;
            font-size: 100%; 
            cursor: pointer;
        }
    }
}

//HOME SECTION//

//OSC//

.clients{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 100%;
    padding: 2% 15%;
    

    .clientdescription{
        color: grey;

        h1{
            font-size: 170%;
            font-weight: 900;

            span{
                color: #ff0f5f;
            }
        }
    }
    .clientimg{
        display: flex;
        justify-content: space-evenly;
        padding: 0 10%;

        img{
            width: 15%;
            height: auto;
           
        }
    }
}

//OSC//

//OE//

.ourexpertise{
    box-sizing: border-box;
    width: 100%;
    height: 110%;
    text-align: center;
    position: absolute;
    top: 160%;
    color: grey;
    background-color: rgb(223, 223, 223);

    h2{
        font-size: 170%;
        font-weight: 900;

        span{
            color: #ff0f5f;
        }
    }

    img{
        width: 18%;
        height: 29%;
        margin: 7% 0;
    }

    h1{
        font-size: 170%;
        margin: 7% 0;
        padding: 0 5%;

        p{
            color: black;
            font-size: 57%;
            font-weight: lighter;
            margin: 0;
            padding: 2% 0;
            
        }

        button {
            border: none;
            padding: 2% 5%;
            color: rgb(255, 255, 255);
            background-color: #ff0f5f;
            text-align: center;
            font-size: 60%; 
            cursor: pointer;
            border-radius: 7px;
        }    
    }

        .head1{
            display: flex;
            width: 100%;
            text-align: left;
            box-sizing: border-box;

            .expertise1{
                padding: 0% 4%;
                display: flex;
                flex-direction: row;
                width: 50%;
                box-sizing: border-box;
            }

            .expertise2{
                padding: 0% 4%;
                display: flex;
                flex-direction: row;
                width: 50%;
                box-sizing: border-box;
            }
        }

        .head2{
            display: flex;
            width: 100%;
            text-align: left;
            box-sizing: border-box;
            
            .expertise3{
                padding: 0% 4%;
                display: flex;
                flex-direction: row;
                width: 50%;
                box-sizing: border-box;
            }

            .expertise4{
                padding: 0% 4%;
                display: flex;
                flex-direction: row;
                width: 50%;
                box-sizing: border-box;
            }
        }
}

//OE//

//About Us//

.about{
    box-sizing: border-box;
    width: 100%;
    height: 84%;
    text-align: right;
    position: absolute;
    top: 282%;
    color: grey;
    display: flex;

    .description{
        box-sizing: border-box;
        width: 50%;
        padding: 4% 0 0 9%;

        h1{
            font-size: 32px;
            font-weight: 900;
            margin: 0;
            
            span{
                color: #ff0f5f;
            }
        }
        p{
            span{
                margin: 0;
                font-size: 24px;
                font-weight: bolder;
                padding: 0 0 0 30%;
                color: black;
            }
        }
        button{
            background-color: white;
            outline: none;
            border: 1px solid black;
            border-radius: 4px;
            padding: 2% 9%;
            font-size: 17px;
        }
    }

    .imageshape{
        box-sizing: border-box;
        width: 50%;
        text-align: center;
        margin: 0;
        padding: 0 2% 0 0;


        img{
            margin: 0;
            width: 72%;
            height: 80%;
        }
    }
}

//About Us//

//Our Projects//

.ourprojects{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 370%;
    color: grey;

    h1{
        font-size: 32px;
        font-weight: 900;
        margin: 0;
        
        span{
            color: #ff0f5f;
        }
    }

    .projectbutton{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 30%;
        list-style: none;

        .title{
            
            font-size: 15px;
            cursor: pointer;
        }

        .title:hover{
            border-bottom: 2px solid #ff0f5f;
        }
        :active{
            color: #ff0f5f;
        }
    }

    .projectimages{
        box-sizing: border-box;
        width: 100%;

        img{
            width: 250px;
            height: 200px;
            margin: 1.5% 1.5%;
        }
    }
}

//Our Projects//



//Footer//
.footer{
    display: flex;
.foot{
    box-sizing: border-box;
    width: 100%;
    height: 50%;
    text-align: center;
    position: absolute;
    top: 480%;
    color: rgb(255, 255, 255);
    background-color: #161325;
    display: flex;
    padding: 2%;

    .foot1{
        width: 30%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        
        .header1{
            width: 100%;
            color: white;
            box-sizing: border-box;
            padding: 50px 0 50px 150px;
            line-height: 0ch;
            text-align: left;
            font-size: 13px;
            
            p{
                color: #ff0f5f;
                font-size: 25px;
                font-weight: 900;
                text-align: left;
            }
        }
        button{
            width: 50%;
            box-sizing: border-box;
            outline: none;
            background-color: #29263f;
            color: white;
            font-size: 16px;
            padding: 3% 0;
            border: 1px solid #383158;
            border-radius: 5px;
            margin: -32px 30px 0 148px;
        }
    }
    
    .foot2{
        width: 70%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 2%;

        .contact{
            width: 100%;
            display: flex;
            font-size: 17px;
            padding: 2% 0% 2% 0;
            justify-content: space-between;
            border-bottom: 2px solid rgba(134, 134, 134, 0.363);
            box-sizing: border-box;
            margin-right: 12%;
            
            .cont{
                display: flex;
                align-items: center;
                padding: 0 2% 0 0;
                margin-right: 9%;

                i{
                    font-size: 30px;
                    color: #ff0f5f;
                    margin-right: 5px;
                }

                div{
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    padding:0 0 0 6%;
                    font-size: 12px;

                    .pi{
                        color: #ff0f5f;
                        font-size: 14px;
                        font-weight: bold;
                    }

                    span{
                        display: flex;
                        width: 120%;
                        color: rgb(168, 168, 168);

                        a{
                            text-decoration: none;
                            color: rgb(168, 168, 168);
                        }
                    }
                }
            }
        }

        .links{
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .same{
                margin-top: 15px;
                margin-right: 120px;
                text-align: left;
                line-height: 0.5ch;
                
                h1{
                    font-size: 17px;
                    font-weight: lighter;
                    margin-bottom: 30px;
                }
                a{
                    text-decoration: none;
                    color: rgb(168, 168, 168);
                    font-size: 13px;
                }
            }

        }
    }
}
.copyright{
    width: 100%;
    background-color: black;
    color: rgb(168, 168, 168);
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 5%;
    position: absolute;
    top: 529%;
}
}
//Footer//